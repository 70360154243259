<template>
	<div class="pricing-1" v-if="loaded">
		<div :class="[$mq.below(960) ? 'container-section-mobile' : 'container-section']">
			<div class="title title-soft-group" style="margin-bottom: 0px;max-width: 100%;" v-html="$t('register.full_packages')"></div>
			<div class="section-description" style="margin-bottom:20px;">
				TBF Digital este un software integrat de management, conceput pentru a facilita stabilirea obiectivelor, atribuirea responsabilităților, descrierea procedurilor, gestionarea
				proiectelor, taskurilor și delegărilor. Acesta oferă, de asemenea, instrumente eficiente pentru evaluarea performanței într-un mod măsurabil și structurat.
			</div>

			<div class="section-webinar-video">
				<div class="video-presentation">
					<div class="video-inside">
						<iframe
							src="https://player.vimeo.com/video/887762780?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
							frameborder="0"
							allow="autoplay; fullscreen; picture-in-picture"
							title="30 Days MBA 2023 - Sesiunea #13 TBF Digital"
						></iframe>
					</div>
				</div>
			</div>

			<div class="pricing-switch">
				<div class="pricing-switch__period" v-bind:class="displayTooltip">
					Plătește anual și salvezi 40%
				</div>
				<div class="tabs tabs_pricing">
					<div class="tabs__nav">
						<a class="tabs__item" :class="{ active: priceTypes == 'year' }" @click="changeTabs('year')" @blur="showTooltip">{{ $t("admin_users.report_interval.year") }}</a>
						<a class="tabs__item" :class="{ active: priceTypes == 'month' }" @click="changeTabs('month')">{{ $t("admin_users.report_interval.month") }}</a>
					</div>
				</div>
			</div>

			<div class="list-prices" v-if="packages[priceTypes]">
				<div class="price-column" v-for="packageItem in packages[priceTypes]">
					<div class="price-box" id="plan1">
						<div class="top-part">
							<div class="header">
								<div class="name">{{ packageItem.name }}</div>
								<div class="name-feature" v-for="feature in packageItem.includes">{{ feature }}</div>
							</div>

							<div class="price">
								<div class="old-price" v-if="parseInt(packageItem.old_price_in_euro) != parseInt(packageItem.price_in_euro)">{{ `${packageItem.old_price_in_euro}€` }}</div>
								<div class="value">{{ `${packageItem.price_in_euro}€` }}</div>
								<span class="period">{{ priceTypes == "month" ? $t("admin_users.report_interval.month") : $t("admin_users.report_interval.year") }} + TVA</span>
							</div>
						</div>

						<div class="action-bundle">
							<button @click="goCart(packageItem.token, packageItem.id)" class="btn-choose-plan">{{ $t("register.choose") }}</button>
							<!-- <span class="info-text">{{ $t('register.prices_without_tva')}}</span> -->
						</div>
					</div>
				</div>

				<!-- Coorporate package -->
				<div class="price-column">
					<div class="price-box" id="plan1">
						<div class="top-part">
							<div class="header">
								<div class="name">{{ $t("landing_prices.corporate") }}</div>
							</div>

							<div class="price building">
								<div class="value"><icon-building /></div>
							</div>
						</div>

						<div class="action-bundle">
							<!-- <button @click="$root.$emit('open_modal', 'demo', {type: 'bundle', option: bundle.type, name: bundle.name})" class="btn-choose-plan">Contactează-ne</button> -->
							<a href="https://resources.tbf.ro/aplica-la-tbf-digital-corporate" target="_blank" class="btn-choose-plan">Contactează-ne</a>
						</div>
					</div>
				</div>
			</div>

			<div class="title title-soft-group mg-top">Iată ce include <span class="yellow">TBF Digital</span></div>
			<div class="section-description">TBF Digital face lucrurile foarte clare și simple.</div>

			<div class="list-features">
				<template v-for="rowItem in listFeatures">
					<component :is="`features-${rowItem.type}`" :data="rowItem" :typeArray="priceTypes" />
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import IconCheckbox from "../Icons/Checkbox";
import IconBuilding from "../Icons/Building";
import IconPlus from "../Icons/Plus";
import IconEquals from "../Icons/Equals";
import IconArrow from "../../assets/landing/Arrow";

// Components Feature List
import FeaturesHeader from "../FeaturesTable/Header";
import FeaturesSubheader from "../FeaturesTable/Subheader";
import FeaturesItems from "../FeaturesTable/Items";

export default {
	components: {
		IconCheckbox,
		IconPlus,
		IconEquals,
		IconArrow,
		IconBuilding,
		FeaturesHeader,
		FeaturesSubheader,
		FeaturesItems,
	},
	data() {
		return {
			activeApplications: [],
			packages: [],
			displayTooltip: "hide",
			priceTypes: "year",
			loaded: true,
			listFeatures: [],
		};
	},
	async mounted() {
		await this.getPackagesData();
	},
	methods: {
		getPackagesData() {
			var params = {};
			if (this.$route.query.reducere) {
				params.reducere = this.$route.query.reducere;
			}

			axios.get(`${process.env.VUE_APP_ORGANIGRAM_LINK}/api/global-packages`, { params: params }).then(({ data }) => {
				if (data.message == "invalid_token") {
					window.location.replace("https://resources.tbf.ro/tbf-digital");
				}

				this.listFeatures = data.data.features;
				this.packages = data.data.packages;
			});
		},
		changeTabs(type) {
			this.priceTypes = type;
		},
		showTooltip() {
			this.displayTooltip = this.displayTooltip == "hide" ? "show" : "hide";
		},
		goCart(token, packageId) {
			var registerUserData = {
				token: token,
				packageId: packageId,
				discount: this.$route.query.reducere ? this.$route.query.reducere : "",
			};

			this.$cookies.set("optionsRegisterUser", JSON.stringify(registerUserData), "1d", null);

			var nextRoute = this.$auth.check() ? "register-packages" : "register";
			this.$router.push({ name: nextRoute });
		},
	},
};
</script>
